<template>
  <div>
    <q-input
      v-model="value"
      :mask="mask"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :rules="rules"
      input-class="cursor-pointer"
      @click.prevent="$refs.qDateProxy.show()"
    >
      <!-- :rules="rules" -->
      <template v-slot:prepend>
        <q-btn icon="event" class="cursor-pointer" color="primary" dense flat unelevated>
          <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
            <q-date
              v-if="!buttons"
              no-unset
              today-btn
              v-model="date"
              :range="range"
              :default-view="defaultView"
              @input="value = date"
            >
              <div class="row items-center justify-end">
                <q-btn v-if="range" v-close-popup label="Fechar" color="negative" no-caps unelevated />
              </div>
            </q-date>

            <q-card v-else flat class="row" unelevated>
              <q-date flat no-unset today-btn v-model="date" :range="range" @input="value = date"> </q-date>
              <q-card-section class="column justify-between items-end">
                <div class="column q-gutter-sm">
                  <q-btn
                    v-for="(presetTimeRange, index) in presetCalendar"
                    :key="index"
                    v-model="value"
                    v-close-popup
                    color="primary"
                    unelevated
                    no-caps
                    style="width: 10em"
                    @click="setValue(presetTimeRange.periodRange)"
                  >
                    {{ presetTimeRange.periodName }}
                  </q-btn>
                </div>
                <q-btn v-if="range" v-close-popup label="Fechar" color="negative" no-caps unelevated />
              </q-card-section>
            </q-card>
          </q-popup-proxy>
        </q-btn>
      </template>
      <template v-slot:append v-if="value && clearable">
        <q-icon name="close" @click.prevent="resetData()" class="cursor-pointer" />
      </template>
    </q-input>
  </div>
</template>

<script>
export default {
  name: 'DateInput',
  props: {
    vModel: [Object, Array, String, Number],
    range: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    outlined: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Boolean,
      default: false
    },
    defaultView: {
      type: String,
      default: 'Calendar'
    }
  },
  data() {
    return {
      date: null
    };
  },

  computed: {
    value: {
      get: function () {
        return this.range ? this.vModel : this.vModel ? this.$moment(this.vModel).format('L') : null;
      },
      set: function (value) {
        if (!value) {
          this.$emit('update', null);
          return;
        }

        if (!this.range) {
          this.$emit('update', value.replace(/\D/g, '-'));
          this.$refs.qDateProxy.hide();
        } else {
          const formattedDate =
            typeof value === 'object' && value
              ? `${this.$moment(value.from).format('L')} - ${this.$moment(value.to).format('L')}`
              : `${this.$moment(value).format('L')} - ${this.$moment(value).format('L')}`;

          this.$emit('update', formattedDate);
        }
      }
    },

    presetCalendar() {
      return [
        {
          periodName: 'Hoje',
          periodRange: {
            to: this.$moment(),
            from: this.$moment()
          }
        },
        {
          periodName: 'Ontem',
          periodRange: {
            to: this.$moment().subtract(1, 'days'),
            from: this.$moment().subtract(1, 'days')
          }
        },
        {
          periodName: 'Últimos 7 dias',
          periodRange: {
            from: this.$moment().subtract(7, 'days'),
            to: this.$moment()
          }
        },
        {
          periodName: 'Últimos 30 dias',
          periodRange: {
            to: this.$moment(),
            from: this.$moment().subtract(30, 'days')
          }
        },
        {
          periodName: 'Esse mês',
          periodRange: {
            from: this.$moment().startOf('month'),
            to: this.$moment().endOf('month')
          }
        },
        {
          periodName: 'Mês passado',
          periodRange: {
            from: this.$moment().subtract(1, 'months').startOf('month'),
            to: this.$moment().subtract(1, 'months').endOf('month')
          }
        }
      ];
    }
  },

  methods: {
    resetData() {
      this.date = null;
      this.value = null;
    },

    setValue(value) {
      this.$emit(
        'update',
        value ? `${this.$moment(value.from).format('L')} - ${this.$moment(value.to).format('L')}` : null
      );
    }
  },

  created() {
    this.range ? (this.mask = '##/##/#### - ##/##/####') : (this.mask = '##/##/####');
  }
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-table',{staticClass:"shadow-8 q-pb-sm",staticStyle:{"width":"44.5%","height":"400px","border-color":"#1976d2"},attrs:{"bordered":"","virtual-scroll":"","title":_vm.type == 'campaign' ? 'Full por Campanha' : 'Full por Canal',"table-header-class":"text-grey","title-class":"text-body1 text-bold","pagination":_vm.pagination,"rows-per-page-options":[0],"columns":[
    {
      name: 'name',
      label: 'Nome',
      field: 'name',
      align: 'center',
      classes: 'text-blue text-bold ellipsis',
      style: 'max-width: 200px'
    },
    { name: 'commission_full', label: 'Valor', field: 'comission_full', align: 'center' },
    { name: 'percentage', label: '%', field: 'percentage', align: 'center' }
  ],"data":_vm.data,"hide-pagination":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body-cell-commission_full",fn:function(props){return [_c('q-td',{staticStyle:{"width":"200px"}},[_c('p',{staticClass:"text-caption q-mb-none text-right"},[_c('b',[_vm._v("R$")]),_vm._v(" "+_vm._s(parseFloat(props.row.comission_full).toLocaleString('pt-br'))+" ")]),_c('div',{class:`bg-blue-2 rounded`,staticStyle:{"width":"100%","height":"6px"}},[_c('div',{class:`bg-blue-${Math.round(props.row.percentage / 14) + 3} rounded`,style:(`width: ${props.row.percentage}%; height: 6px`)})])])]}},{key:"body-cell-percentage",fn:function(props){return [_c('q-td',{},[_c('div',{staticClass:"bg-blue-2 rounded flex justify-center items-center text-bold",style:(`height: 35px; width: 65px; margin: 0 auto; border: 1px solid #1976D2`)},[_vm._v(" "+_vm._s(props.row.percentage)+" % ")])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <q-card class="q-pa-md shadow-8" bordered style="width: 28%; border-color: #0086e6">
    <p class="text-body1 text-bold">Comissão Full por Mês</p>
    <canvas id="commission-full-bar-chart"></canvas>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryBarChart',

  props: {
    data: { require: true, type: Array }
  },

  data() {
    return {
      chart: null,
      months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    };
  },

  watch: {
    data: function () {
      // console.log(this.data);
      if (this.data.length != 0) {
        this.mountChart();
      }
    }
  },
  methods: {
    async mountChart() {
      if (!this.chart) {
        var ctx = document.getElementById('commission-full-bar-chart');
        const labels = this.data.map((val) => this.months[Number(val.period.split('/')[0]) - 1]);
        const data = this.data.map((val) => val.comission_full);
        this.chart = new Chart(ctx, {
          type: 'bar',

          data: {
            labels: labels,
            datasets: [
              {
                label: 'Comissão Full',
                data: data,
                backgroundColor: 'rgba(0, 149, 255, 1)',
                borderColor: 'rgba(0, 200, 255, 1)',
                borderWidth: 1
              }
            ]
          },
          options: {
            tooltips: {
              callbacks: {
                // eslint-disable-next-line
                label: function (item, chart) {
                  return ' R$ ' + item.yLabel.toLocaleString('pt-br');
                }
              }
            },

            legend: {
              position: 'bottom'
            },

            elements: {
              bar: {
                borderRadius: 5
              }
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return (value / 1000).toLocaleString('pt-br') + 'k';
                    },

                    fontSize: 10
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontSize: 10
                  }
                }
              ]
            }
          }
        });
      } else {
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = this.data.map((val) => val.comission_full);
        });
        this.chart.update();
      }
    }
  }
};
</script>

<style></style>

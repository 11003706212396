<template>
  <q-page padding>
    <q-toolbar class="q-px-md q-mb-md">
      <q-toolbar-title class="text-weight-medium">Dashboard </q-toolbar-title>

      <q-checkbox
        label="Ignorar campanhas de teste"
        true-value="1"
        false-value="0"
        v-model="ignoreTestCampaigns"
        class="q-mr-md"
      />
      <q-input outlined readonly v-model="displayPeriod" dense class="q-mr-md" label="Período" />
      <q-btn-dropdown no-caps outline content-style="width: 380px" :menu-offset="[0, 10]" label="Filtros">
        <div style="" class="row">
          <div class="col-12 justify-between items-center row" v-for="(filter, index) in filters" :key="index">
            <div class="items-center flex q-pa-md" rounded>{{ filter.label }}</div>
            <q-btn
              color="white"
              text-color="blue"
              @click="filter.editing = !filter.editing"
              unelevated
              :icon="filter.editing ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-down'"
              style="font-size: 12px"
              round
              no-caps
            />
            <transition name="fade">
              <div class="col-12 q-pa-md" style="width: full" v-if="filter.editing">
                <q-select
                  v-if="filter.name != 'period'"
                  v-model="filter.value"
                  :options="filter.name == 'statuses' ? filter.options : filter.optionsFiltered"
                  map-options
                  emit-value
                  :label="filter.label"
                  :option-label="filter.option_label"
                  :option-value="filter.option_value"
                  outlined
                  clearable
                  use-input
                  @filter="(val, update) => update(selectFilter(val, index, filter.option_label))"
                />
                <date-input
                  buttons
                  outlined
                  label="Período"
                  :v-model="filter.value"
                  @update="
                    (val) => {
                      filter.value = val;
                    }
                  "
                  today-btn
                  range
                  v-else
                />
              </div>
            </transition>
          </div>
        </div>
        <div class="flex justify-around q-pa-md q-mt-sm">
          <q-btn label="Remover Filtros" unelevated color="black" style="width: 160px" @click="resetFilters" />
          <q-btn
            label="Aplicar Filtros"
            unelevated
            color="primary"
            outline
            style="width: 160px"
            @click="retrieveDashboardResume"
          />
        </div>
      </q-btn-dropdown>
    </q-toolbar>

    <div class="full-width flex my-grid justify-around">
      <SummaryCard v-for="(data, index) in summariesData" :data="data" :key="index" />
    </div>

    <div class="full-width flex justify-around q-mt-lg">
      <SummaryTable :data="commissionPerCampaign" :type="'campaign'" />
      <SummaryTable :data="commissionPerChannel" :type="'channel'" />
    </div>
    <div class="full-width flex justify-around q-mt-lg">
      <!-- <q-card class="q-pa-md" bordered style="width: 44.5%"> -->
      <!--   <canvas id="myChart"></canvas> -->
      <!-- </q-card> -->

      <SummaryBarChart :data="commissionPerMonthData" />
      <SummaryPizzaChart :data="commissionPerStautsData" />
      <SummaryLineChart :data="commissionPerDay" />
    </div>
  </q-page>
</template>

<script>
import SummaryCard from '@/components/smartDashboard/SummaryCard.vue';
import SummaryTable from '@/components/smartDashboard/SummaryTable.vue';
import SummaryBarChart from '@/components/smartDashboard/SummaryBarChart.vue';
import SummaryPizzaChart from '@/components/smartDashboard/SummaryPizzaChart.vue';
import SummaryLineChart from '@/components/smartDashboard/SummaryLineChart.vue';
import DateInput from '@/widgets/inputsGlobal/DateInput.vue';
import DashboardResumeService from '@/services/DashboardResume.js';
import AffiliateService from '@/services/AffiliateService.js';
import AdvertiserService from '@/services/AdvertiserService.js';
import UserService from '@/services/UserService.js';

export default {
  name: 'SmartDashboard',
  components: { SummaryCard, DateInput, SummaryTable, SummaryBarChart, SummaryPizzaChart, SummaryLineChart },
  mixins: [DashboardResumeService, AffiliateService, AdvertiserService, UserService],
  data() {
    return {
      commissionPerCampaign: [],
      commissionPerChannel: [],
      commissionPerMonthData: [],
      commissionPerStautsData: [],
      commissionPerDay: [],
      displayPeriod: '',
      ignoreTestCampaigns: '1',
      summariesData: {
        comission_full: { title: 'Comissão Full', value: '', color: '#17ccf3', icon: 'mdi-trending-up' },
        cost: { title: 'Custo', value: '', color: '#f8aa4c', icon: 'mdi-cash' },
        margin: { title: 'Margem', value: '', color: '#FBCB42', icon: 'mdi-chart-line' },
        margin_percent: { title: '% Margem', value: '', color: '#766BFB', icon: 'mdi-percent-outline' }
      },
      filters: [
        { label: 'Periodo', name: 'period', value: '', editing: false },
        {
          label: 'Status',
          name: 'statuses',
          value: '',
          editing: false,
          options: [
            { label: 'Aprovado', value: 2 },
            { label: 'Pendente', value: 1 },
            { label: 'Recusado', value: 0 }
          ]
        },
        {
          label: 'Comercial',
          name: 'commercial',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        {
          label: 'Atendimento',
          name: 'operational',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'user_id'
        },
        {
          label: 'Campanha',
          name: 'campaigns',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        },
        {
          label: 'Canal',
          name: 'channels',
          value: '',
          editing: false,
          options: [],
          optionsFiltered: [],
          option_label: 'name',
          option_value: 'id'
        }
      ]
    };
  },
  methods: {
    async retrieveDashboardResume() {
      try {
        this.onLoading(true);
        var filterString = '%3F';
        var filterChartsString = '%3F';
        this.filters.forEach((filter) => {
          if (filter.name == 'period') {
            var [startDate, endDate] = filter.value.split(' - ');
            filterString += `start_date%3D${this.formatToEn(startDate)}%26end_date%3D${this.formatToEn(endDate)}%26`;
          } else if (filter.value !== '' && filter.value !== null) {
            filterString += filter.name + '%3D' + filter.value + '%26';
            filterChartsString += filter.name + '%3D' + filter.value + '%26';
          }
        });
        filterString += 'ignore_test%3D' + this.ignoreTestCampaigns;
        filterChartsString += 'ignore_test%3D' + this.ignoreTestCampaigns;
        const { data } = await this.getSmartDashboardResume(filterString);

        for (const key in data) {
          this.summariesData[key].value = data[key];
        }

        this.commissionPerCampaign = (await this.getCampaignCommissionFull(filterString)).data;

        this.commissionPerChannel = (await this.getChannelCommissionFull(filterString)).data;

        this.commissionPerMonthData = (await this.getCommissionPerMonth(filterChartsString)).data;

        this.commissionPerStautsData = (await this.getCommissionByStatus(filterString)).data.sort(
          (a, b) => a.status - b.status
        );
        
        this.commissionPerDay = (await this.getCommissionFullDaily(filterChartsString)).data;

        this.displayPeriod = this.filters[0].value;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    selectFilter(val, index, prop = null) {
      const needle = val.toLocaleLowerCase();
      this.filters[index].optionsFiltered = needle
        ? this.filters[index].options.filter((v) =>
            prop ? v[prop].toLocaleLowerCase().includes(needle) : v.toLocaleLowerCase().includes(needle)
          )
        : this.filters[index].options;
    },

    async retrieveSelectOptions() {
      try {
        this.onLoading(true);
        const options = await Promise.all([this.getManagersOptions(), this.getAllCampaigns(), this.getAllSites()]);

        this.filters[2].options = this.filters[2].optionsFiltered = options[0].data;
        this.filters[3].options = this.filters[3].optionsFiltered = options[0].data;
        this.filters[4].options = this.filters[4].optionsFiltered = options[1].data;
        this.filters[5].options = this.filters[5].optionsFiltered = options[2].data;
      } catch (error) {
        this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    resetFilters() {
      this.filters.forEach((filter) => {
        filter.value =
          filter.name == 'period'
            ? `${this.$moment().subtract(7, 'days').format('L')} - ${this.$moment().format('L')}`
            : '';
      });
    }
  },

  created() {
    this.filters[0].value = `${this.$moment().subtract(7, 'days').format('L')} - ${this.$moment().format('L')}`;
    this.retrieveDashboardResume();
    this.retrieveSelectOptions();
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

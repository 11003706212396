import { render, staticRenderFns } from "./SummaryCard.vue?vue&type=template&id=f977f2c4"
import script from "./SummaryCard.vue?vue&type=script&lang=js"
export * from "./SummaryCard.vue?vue&type=script&lang=js"
import style0 from "./SummaryCard.vue?vue&type=style&index=0&id=f977f2c4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QIcon});

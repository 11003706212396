<template>
  <q-card class="q-pa-md shadow-8" bordered style="width: 28%; border-color: #0086e6">
    <p class="text-body1 text-bold">Comissão Full Por Dia</p>
    <canvas id="commission-full-daily-value-chart"></canvas>
  </q-card>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'SummaryLineChart',

  props: {
    data: { require: true, type: Array, default: () => [{ status_description: '', quantity: 0 }] }
  },

  data() {
    return {
      chart: null
    };
  },

  watch: {
    data: function () {
      // console.log(this.data);
      this.mountChart();
    }
  },
  methods: {
    async mountChart() {
      if (!this.chart) {
        var ctx = document.getElementById('commission-full-daily-value-chart');
        const data = this.data.map((val) => val.comission_full);
        const labels = this.data.map((val) => val.day.slice(0, 5));
        this.chart = new Chart(ctx, {
          type: 'line',

          data: {
            labels: labels,
            datasets: [
              {
                label: 'Comissão Full',
                data: data,
                backgroundColor: 'rgba(255, 204, 247, 1)',
                borderColor: 'rgba(20, 50, 155, 1)',
                borderWidth: 1
              }
            ]
          },
          options: {
            tooltips: {
              callbacks: {
                //eslint-disable-next-line
                label: function (item, chart) {
                  console.log(item, chart);
                  // var label = this._data.labels[item['index']].split(' | ');
                  // return label[0] + ':  ' + chart['datasets'][0]['data'][item['index']] + ' % (' + label[1] + ')';
                  return 'Comissão: R$ ' + item.yLabel.toLocaleString('pt-br');
                }
              }
            },
            legend: {
              position: 'bottom'
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    // eslint-disable-next-line
                    callback: function (value, index, values) {
                      return 'R$ ' + value.toLocaleString('pt-br');
                    },
                    fontSize: 10
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    fontSize: 10
                  }
                }
              ]
            }
          }
        });
      } else {
        this.chart.data.labels = this.data.map((val) => val.day.slice(0, 5));
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = this.data.map((val) => val.comission_full);
        });
        this.chart.update();
      }
    }
  }
};
</script>

<style></style>

const serviceApp = process.env.VUE_APP_AFFILIATE_SERVICE || 'AFFILIATE';

export default {
  methods: {
    async listActiveCampaigns(affiliateId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/affiliate/${affiliateId}`);
    },

    /*  ATENÇÃO
     *  O ID do afiliado só deverá ser passado caso o usuário logado seja Admin
     */
    async getAffiliateData(affiliateId) {
      const path = affiliateId ? `/api/get/affiliate/${affiliateId}` : '/api/v2/get/affiliate';
      return await this.$http.get(`/redirect?app=${serviceApp}&path=${path}`);
    },

    async getAffiliateSiteData(siteId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/affiliate/edit/site/${siteId}`);
    },

    async getAffiliateSiteTips() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/affiliate/list_site_type`);
    },

    //Realiza a mesma coisa que o método abaixo, porém o método abaixo é usado em outra tela
    //diferente da que estamos atualizando
    //quando a próxima tela for atualizada, apenas mudar para o método com o endpoint certo
    async getAffiliateSiteCategoryFilter() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/affiliate/filter_site_categories`);
    },
    async getAffiliateSiteCategory() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/affiliate/filter_site_categories`);
    },

    async getAffiliateSettings(affiliateId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/affiliate_settings/${affiliateId}`);
    },

    async getAffiliatePaymentInfos(affiliateId) {
      return await this.$http.get(
        `/v2/redirect?app=${serviceApp}&path=/api/get/affiliate/payment_infos/list/${affiliateId}`
      );
    },

    /*  ATENÇÃO
     *  O ID do afiliado só deverá ser passado caso o usuário logado seja Admin
     */
    async editAffiliateData(affiliateId, affiliateData) {
      if (affiliateId) {
        return await this.$http.put(`v2/redirect`, {
          app: serviceApp,
          path: `/api/edit/affiliate/${affiliateId}`,
          params: {
            data: affiliateData
          }
        });
      }
      return await this.$http.post('/v2/redirect', {
        app: serviceApp,
        path: '/api/v2/edit/affiliate',
        params: {
          data: affiliateData
        }
      });
    },

    /*  ATENÇÃO
     *  O ID do afiliado só deverá ser passado caso o usuário logado seja Admin
     */
    async getAffiliateDataPayment(affiliateId) {
      const path = affiliateId
        ? `/api/get/affiliate/payment_infos/list/${affiliateId}`
        : `/api/v2/get/affiliate/payment_infos`;
      return await this.$http.get(`/redirect?app=${serviceApp}&path=${path}`);
    },

    /*  ATENÇÃO
     *  O ID do afiliado só deverá ser passado caso o usuário logado seja Admin
     */
    async editAffiliateDataPayment(affiliateId, affiliateData) {
      const path =
        this.user.profile == 'afiliado'
          ? `/api/v2/edit/affiliate/payment_infos/${affiliateId}`
          : `/api/edit/affiliate/payment_infos/${affiliateId}`;
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path,
        params: {
          data: affiliateData
        }
      });
    },

    async editAffiliateAccessEmail(affiliateId, affiliateNewEmail) {
      return await this.$http.put(`v2/affiliate/settings/email`, {
        email: affiliateNewEmail,
        affiliate_id: affiliateId
      });
    },

    async updateAffiliateStatus(affiliateId, status) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/affiliate/status/${affiliateId}`,
        params: {
          data: {
            status: status
          }
        }
      });
    },

    async createAffiliateDataPayment(affiliateData) {
      let path =
        this.user.profile == 'afiliado'
          ? '/api/v2/create/affiliate/payment_info/'
          : '/api/create/affiliate/payment_info';
      return await this.$http.post(`v2/redirect`, {
        app: serviceApp,
        path,
        params: {
          data: affiliateData
        }
      });
    },

    async editAffiliateChannel(channelId, channelData) {
      const path =
        this.user.profile == 'afiliado'
          ? `/api/v2/edit/affiliate/site/${channelId}`
          : `/api/edit/affiliate/site/${channelId}`;
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path,
        params: {
          data: channelData
        }
      });
    },

    async createAffiliateChannel(channelData) {
      const path = this.user.profile == 'afiliado' ? '/api/v2/create/affiliate/site' : '/api/create/affiliate/site';
      return await this.$http.post(`v2/redirect`, {
        app: serviceApp,
        path,
        params: {
          data: channelData
        }
      });
    },

    async inactiveAffiliateChannel(channelId) {
      return await this.$http.delete(`v2/redirect?app=${serviceApp}&path=/api/v2/delete/affiliate/site/${channelId}`);
    },
    /*  ATENÇÃO
     *  O ID do afiliado só deverá ser passado caso o usuário logado seja Admin
     */
    async getAffiliateOptinEmails(affiliateID) {
      const path = affiliateID
        ? `/api/optin_email/get_by_affiliate/${affiliateID}`
        : '/api/v2/get/affiliate/optin_email';
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=${path}`);
    },

    async updateAffiliateOptinEmails(formData) {
      const path = formData.affiliateId ? '/api/optin_email/' : '/api/v2/optin_email/';
      return await this.$http.post('v2/redirect', {
        app: serviceApp,
        path: path,
        params: {
          data: formData
        }
      });
    },

    async updateAffiliatePassword(affiliateID, password, current_password) {
      const path = affiliateID ? `/edit/user/resetaffiliatepass/${affiliateID}` : '/edit/user/resetpass';
      const payload = affiliateID ? { password } : { password, current_password };
      return await this.$http.put(path, payload);
    },

    async getAffiliateSiteActiveList() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/v2/get/affiliate/site_active/filter`);
    },

    async filterAffiliateSiteActive() {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/v2/get/affiliate/site_active/filter`);
    },
    async createPasswordReset(email) {
      return await this.$http.post('/create/user/reset', { email });
    },

    async getAllSites() {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/affiliate/site/filter`);
    },

    async getAffiliateSiteActiveListByID(id) {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/get/affiliate/site_active/list/${id}`);
    },

    async createNewAffiliateAccess(accessData) {
      return await this.$http.post('/v2/affiliate/settings/users', accessData);
    },

    async deleteAffiliateAccess(id) {
      return await this.$http.delete(`/v2/affiliate/settings/users/${id}`);
    },

    async editAffiliateComercialManager(affiliate_id, comercial_manager_id) {
      try {
        this.onLoading(true);
        const { data, status } = await this.$http.put('/v2/redirect', {
          app: serviceApp,
          path: '/api/edit/affiliate/comercial_manager',
          params: {
            data: {
              affiliate_id,
              comercial_manager_id
            }
          }
        });
        if (status == 200) {
          this.successNotify(data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async editAffiliateServiceManager(affiliate_id, service_manager_id) {
      try {
        this.onLoading(true);
        const { data, status } = await this.$http.put('/v2/redirect', {
          app: serviceApp,
          path: '/api/edit/affiliate/service_manager',
          params: {
            data: {
              affiliate_id,
              service_manager_id
            }
          }
        });
        if (status == 200) {
          this.successNotify(data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
